import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import cond from 'cond-construct'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
	UseFormResetField,
	UseFormSetValue,
	useFieldArray,
	useForm,
	useWatch
} from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { EmptyBagIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import { Speaker } from 'components/audio-player'
import { MWRAInfoCard } from 'components/cards/mwra-info-card'
import { CounterInput } from 'components/inputs/counter'
import { Modal } from 'components/modals/modal'
import { WomanConditionTypes } from 'constants/constants'
import { useAppDispatch, useAppSelector } from 'hooks'
import { addSale } from 'slices/queued'
import { getLocalizedName, getTKey, t } from 'utils/language'
import { getDirection, isMalnourishedMWRA } from 'utils/string'
import toast from 'react-hot-toast'

const tKey = getTKey('sales')

enum FormSteps {
	LIST = 1,
	DETAILS,
	CART,
	COMPLETE
}

type StateType = {
	step: FormSteps
	displayExitModal: boolean
	selectedProduct?: ID
}

export const CreateSale = () => {
	const [successModal, setSuccessModal] = useState<boolean>(false);
	const [saleData, setSaleData] = useState<any>(null)

	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	const navigate = useNavigate()

	const pendingSales = useAppSelector(state => state.queued.sales)
	const [state, setState] = useState<StateType>({
		step: FormSteps.LIST,
		displayExitModal: false
	})
	const [showNoSaleModal, setShowNoSaleModal] = useState(false)

	const crp = useAppSelector(state => state.auth.profile)
	let productsData = useAppSelector(state => state.db.products);

	// console.log("productsData",productsData)
	// const productsDataArray = Object.values(productsData);

	const targetIds = [1, 3, 31]; // The products you want to filter by id

	// const filteredProducts = productsDataArray.filter(p => targetIds.includes(p.id));
	// console.log("filteredProducts",filteredProducts)
	const filteredObject = productsData;
	// const filteredObject =  Object.fromEntries(
	// 	Object.entries(productsData).filter(([key, product]) =>  targetIds.includes(product.id))
	//   );

	const [products, setProducts] = useState<any>(filteredObject)
	let latestProduct = products
	useEffect(() => {
		const updatedState = updateProductData(filteredObject, pendingSales);
		setProducts(updatedState)
	}, [pendingSales, filteredObject])
	//


	const updateProductData = (originalState: any, pendingSales: any) => {
		// Step 1: Create a new state object
		let newState = { ...originalState };

		// Step 2: Update availableQuantity based on pendingSales
		for (const saleId in pendingSales) {
			const sale = pendingSales[saleId];
			for (const saleItem of sale.saleItems) {
				const productId = saleItem.productId;
				const quantity = saleItem.quantity;

				// Check if the product exists in the newState
				if (newState[productId]) {
					// Update availableQuantity
					newState[productId] = {
						...newState[productId],
						availableQuantity:
							newState[productId].availableQuantity - quantity,
					};
				}
			}
		}

		// Step 3: Return the updated state (immutable)
		return newState;
	};

	// Example usage

	// Log the updated state

	// Display the updated products data
	const mwras = useAppSelector(state => state.db.mwras)

	const dispatch = useAppDispatch()

	const { id } = useParams()
	const mwra = id ? mwras[parseInt(id)] : undefined
	const sale: Sale = {
		id: 0,
		status: 'completed',
		saleDate: DateTime.now().toISO(),
		mwraId: mwra?.id ?? 0,
		crpId: crp.id,
		saleItems: [],
		total: 0
	}
	const getPageTitle = () => {
		let title
		switch (state.step) {
			case FormSteps.CART:
				title = t(tKey('title.cart'))
				break
			case FormSteps.LIST:
				title = t(tKey('title.productCatalog'))
				break
			case FormSteps.COMPLETE:
				title = ''
				break
			default:
				title = ''
				break
		}

		return title
	}

	const getAudioTitle = () => {
		let title
		switch (state.step) {
			case FormSteps.CART:
				title = 'basket.mp3'
				break
			case FormSteps.LIST:
				title = 'products.mp3'
				break
			case FormSteps.COMPLETE:
				title = ''
				break
			default:
				title = ''
				break
		}

		return title
	}

	const pageTitle = getPageTitle()
	const audioName = getAudioTitle()

	const schema = yup.object({
		saleItems: yup.array().required(t('errors.required', { field: t(tKey('labels.products')) })),
		total: yup.number().required(t('errors.required', { field: t(tKey('labels.total')) })),
		crpId: yup.number().required(t('errors.required', { field: t(tKey('labels.crpId')) })),
		mwraId: yup.number().required(t('errors.required', { field: t(tKey('labels.mwraId')) })),
		saleDate: yup.string().required(t('errors.required', { field: t(tKey('labels.date')) }))
	})
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
		setValue,
		control
	} = useForm<Sale>({
		resolver: yupResolver(schema as any),
		context: { step: state.step },
		defaultValues: {
			crpId: crp.id,
			mwraId: mwra?.id,
			total: 0,
			saleItems: [],
			saleDate: DateTime.now().toISO()
		},
		mode: 'all'
	})

	const total = useWatch({
		control,
		name: 'total',
		defaultValue: 0
	})

	const productsInCart = useWatch({
		control,
		name: 'saleItems'
	})

	useEffect(() => {
		const totalBill = productsInCart.reduce((agg, curr) => {
			return agg + products[curr.productId].price * (curr.quantity ?? 0)
		}, 0)
		setValue('total', totalBill)
	}, [productsInCart])

	const { append, remove } = useFieldArray({
		name: 'saleItems',
		control,
		keyName: 'customId'
	})

	const productIndex = getValues().saleItems.findIndex(
		saleItem => saleItem.productId === state.selectedProduct
	)

	const renderComponent = cond([
		[
			state.step === FormSteps.LIST,
			() => (
				<ProductList
					errors={errors}
					register={register}
					control={control}
					products={products}
					onProductClicked={(productId) => {
						setState({ ...state, selectedProduct: productId, step: FormSteps.DETAILS });
						if (products[productId].availableQuantity > 0) {
							setState({ ...state, selectedProduct: productId, step: FormSteps.DETAILS });
						} else {
							// Handle the case where the available quantity is 0 or negative
							console.warn('Product not available.');
							return toast.error('Product not available.')
							// You might want to show a message to the user or take other actions.
						}
					}}


				// onProductClicked={productId =>
				// 	setState({ ...state, selectedProduct: productId, step: FormSteps.DETAILS })
				// }
				/>
			)
		],
		[
			state.step === FormSteps.DETAILS,
			() => (
				<ProductDetailsForm
					products={products}
					productIndex={productIndex}
					goBack={() => setState({ ...state, step: FormSteps.LIST })}
					errors={errors}
					register={register}
					control={control}
				/>
			)
		],
		[
			state.step === FormSteps.CART,
			() => (
				<CartForm
					products={products}
					onProductClicked={productId =>
						setState({ ...state, selectedProduct: productId, step: FormSteps.DETAILS })
					}
					goBack={() => setState({ ...state, step: FormSteps.LIST })}
					errors={errors}
					register={register}
					control={control}
				/>
			)
		]
	])

	const previousStep = () => {
		const saleItems = getValues().saleItems

		for (let index = 0; index < saleItems.length; index++) {
			if ((saleItems[index].quantity ?? 0) === 0) {
				remove(index)
			}
		}

		if (state.step === FormSteps.LIST) {
			return
		}

		setState(prevState => ({
			...prevState,
			step: FormSteps.LIST
		}))
	}

	const onSubmit = handleSubmit(data => {
		// NOTE: Temporary Fix for https://github.com/CERP/micare/issues/294
		// traverse all the sale items and multiply
		// quanity with the product amount
		setSaleData(data)
		setSuccessModal(true);




	})

	const handleSubmitConfirmSale = () => {
		const saleItems = saleData.saleItems.map((item: any) => ({ ...item, amount: item.quantity * item.amount }));

		dispatch(addSale({ ...saleData, saleItems: saleItems }))
		setState({ ...state, step: FormSteps.COMPLETE });
		setSuccessModal(false)

	}


	const saveEmptySale = () => {
		dispatch(addSale(sale))
		setState({ ...state, step: FormSteps.COMPLETE })
	}

	const dir = getDirection()

	return (
		<AppLayout
			showHeader
			showCrossIcon
			onBackArrowPress={() => {
				/* eslint-disable no-restricted-globals */
				if (state.step !== FormSteps.COMPLETE && state.step !== FormSteps.LIST) {
					previousStep();
				} else {
					history.back();
				}
				/* eslint-enable no-restricted-globals */
			}}


			onCrossPress={() => setState({ ...state, displayExitModal: true })}>
			<div dir={dir} className="space-y-4 pb-40">
				<MWRAInfoCard mwra={mwra as MWRA} cluster={crp.cluster} />
				{/* {state.step === FormSteps.LIST && (
					<div className="px-1 py-2">
						<h1 className="font-semibold text-lg">{mwra?.name}</h1>
						<div className="flex flex-row">
							<p>{t(`plw.options.${mwra?.condition}`)}</p>
							{isMalnourished && (
								<p className="text-red-600"> - {t('followUp.labels.malnourished')}</p>
							)}
						</div>
					</div>
				)} */}
				<div className="space-y-4">
					<div className="flex items-center">
						{audioName && <Speaker filename={audioName} />}
						<h1 className="text-xl font-semibold">{pageTitle}</h1>
					</div>
					<form onSubmit={onSubmit} className="space-y-2 w-full font-bold">
						{state.step === FormSteps.LIST && (
							<>
								<button
									className="w-full mb-2 rounded-md font-semibold bg-white border border-gray-200 py-2"
									type="button"
									onClick={() => setShowNoSaleModal(true)}>
									{t('products.buttons.noSale')}
								</button>
								{showNoSaleModal && (
									<Modal
										title={t('products.label.noSale')}
										onClose={() => setShowNoSaleModal(false)}
										showCrossIcon={true}>
										<div className="flex flex-row justify-center items-center mt-4">
											<button
												onClick={() => setShowNoSaleModal(false)}
												type="button"
												className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-red-500 font-bold py-2 text-white">
												{t('products.noSales.buttons.yes')}
											</button>
											<button
												onClick={() => saveEmptySale()}
												type="button"
												className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-green-500 font-bold py-2 text-white">
												{t('products.noSales.buttons.no')}
											</button>
										</div>
									</Modal>
								)}
							</>
						)}
						<>{renderComponent}</>
						{state.step === FormSteps.CART && productsInCart.length > 0 && (
							<div className="flex flex-col p-8 w-full items-center justify-center border border-gray-200 text-lg font-semibold absolute bg-white bottom-4 left-0 right-0">
								<button
									type="submit"
									className="p-4 max-w-max  flex flex-row justify-between bg-green-500 text-white rounded-full ">
									<CheckIcon className="h-10" />
								</button>
								<p className="mt-2">
									{total} <span>{t('products.label.rupees')}</span>
								</p>
							</div>
						)}
					</form>
				</div>
				{state.displayExitModal && (
					<Modal
						title={t('common.labels.backWithoutSaving')}
						onClose={() => setState({ ...state, displayExitModal: false })}
						showCrossIcon={true}>
						<div className="flex flex-row justify-center items-center mt-4">
							<button
								onClick={event => {
									event.preventDefault()
									/* eslint-disable no-restricted-globals */

									history.back()
									/* eslint-disable no-restricted-globals */

								}}
								type="button"
								className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-red-500 font-bold px-8 py-2 text-white">
								{t('buttons.yes')}
							</button>
							<button
								onClick={event => {
									event.preventDefault()
									setState({ ...state, displayExitModal: false })
								}}
								type="button"
								className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-green-500 font-bold px-8 py-2 text-white">
								{t('buttons.no')}
							</button>
						</div>
					</Modal>
				)}
				{state.step < FormSteps.CART && (
					<div style={{
						position: "fixed",
						bottom: 0,
						width: width > 600 ? "511px" : "100%",
					}} className="absolute w-full mx-auto flex flex-row items-center justify-center border border-gray-200 bg-white bottom-4 left-0 p-6 right-0">
						<button
							onClick={() => setState({ ...state, step: FormSteps.CART })}
							className="px-8 py-6 flex flex-row justify-between items-center w-full bg-blue-800 text-white text-lg rounded-lg font-semibold">
							<p>{t('products.buttons.viewCart')}</p>
							<p>
								{total} <span>{t('products.label.rupees')}</span>
							</p>
						</button>
					</div>
				)}
				{state.step === FormSteps.COMPLETE && (
					<div className="flex flex-col p-8 w-full items-center justify-center space-y-2 absolute text-center left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
						<div className="flex items-center">
							<Speaker filename="orderPlacedSuccessfully.mp3" />
							<h1 className="text-center">{t('products.label.success')}</h1>
						</div>
						<button
							/* eslint-disable no-restricted-globals */

							onClick={() => navigate(`/mwras/${mwra?.id}`)}											/* eslint-disable no-restricted-globals */

							className="p-4 max-w-max flex flex-row justify-between bg-green-500 text-white rounded-full ">
							<CheckIcon className="h-10" />
						</button>
					</div>
				)}
				{successModal && (
					<Modal
						onClose={() => {
							setSuccessModal(false);
						}}
						showCrossIcon={true}
						className="space-y-6"
					>
						{/* Display summary for productsDataFinal */}
						<h2 className="text-2xl font-bold text-center mb-4">{t("viewOrderSummary.orderDetails.confirmationMessageSales")}</h2>
						{/* <div className="flex-col border border-gray-200 divide-y divide-gray-200 bg-white rounded-md w-full px-2 py-3s space-y-1 mt-2">
                <h1 className=" text-md font-semibold">{t("viewOrderSummary.orderDetails.summaryOfSelectedRequisition")}</h1>
                <Table headers={[ t("viewOrderSummary.tableHeaders.name"), t("viewOrderSummary.orderDetails.subtotalPKR")]} renderComponent={renderTBody} isReport={true} />
              </div>

              <h2 className="text-3xl font-bold text-center mb-4">{t("viewOrderSummary.orderDetails.totalAmount")}: PKR {totalAmount}</h2>
             */}

						{/* Confirm and Cancel buttons */}
						<div className="flex justify-center items-center mt-4">
							<button onClick={handleSubmitConfirmSale} type="button" className="w-48 px-2 py-3 inline-flex items-center justify-center text-white bg-[#00cfae]  font-bold rounded-3xl text-md text-center mr-2 mb-2">
								{t("buttons.submit")}
							</button>
							{/* <button
									onClick={() => setSuccessModal(false)}
									type="button"
									className="w-48 px-2 py-3 inline-flex items-center justify-center text-white bg-blue-600  font-bold rounded-3xl text-md text-center mb-2">
									No
								</button> */}
						</div>
					</Modal>
				)}
			</div>
		</AppLayout>
	)
}

interface FormProps {
	register?: UseFormRegister<Sale>
	errors?: FieldErrors<Sale>
	control?: Control<Sale, any>
	setValue?: UseFormSetValue<Sale>
	resetField?: UseFormResetField<Sale>
	products: Products
}

const ProductList = ({
	products,
	onProductClicked,
	control
}: FormProps & {
	onProductClicked: (productId: ID) => void
}) => {
	const { fields, append } = useFieldArray({
		name: 'saleItems',
		control,
		keyName: 'customId'
	})

	const mwras = useAppSelector(state => state.db.mwras)
	const { id } = useParams()

	const mwra = mwras[Number(id)]
	const isMalnourished = mwra && isMalnourishedMWRA(mwra.muac)

	// let productList = Object.values(products);
	// .filter(product => {
	// 	// if the woman is pregnant, lactating or malnourished
	// 	// CRP can sold all products. Otherwise show all other
	// 	// products except wellma

	// 	return [WomanConditionTypes.PREGNANT, WomanConditionTypes.LACTATING].includes(
	// 		mwra?.condition as WomanConditionTypes
	// 	) || isMalnourished
	// 		? true
	// 		: !product.isWellma
	// })

	// if(mal){
	// 	all
	// }else{
	// 	PregnantIcon,loc
	// }
	//type WomanCondition = 'lactating' | 'mwra' | 'pregnant' | 'deceased'
	//Product Wellma -> (Status P L AND MUAC => 23) OR (Status P L M AND MUAC < 23)
	let productList = Object.values(products).filter((product: any) => {
		let check = true;
		if (product.isMMS || product.isWellma) {
			if (product.isMMS) {
				if ((mwra.condition == 'pregnant' || mwra.condition == 'lactating') && !isMalnourished) {
					check = true;
				} else {
					check = false;
				}
			} else { //product is Wellma
				if (isMalnourished) {
					check = true;
				} else if ((mwra.condition == 'pregnant' || mwra.condition == 'lactating') && !isMalnourished) {
					check = true;
				} else {
					check = false;
				}
			}
		} else {
			check = true;
		}
		return check;
	});



	return (
		<div className="space-y-4">
			{Object.values(productList).map(product => {
				return (
					<div
						key={product.id}
						onClick={() => {
							const isAlreadyAdded = fields.findIndex((addedProduct) => addedProduct.productId === product.id) !== -1;

							if (!isAlreadyAdded) {
								if (product.availableQuantity != 0) {
									append({ quantity: 1, productId: product.id, amount: product.price } as SaleItem);
								}
								else {
									toast.error('Product not available.')
									return false
								}
							} else {
								// Handle the case where the product is already added or not available
								if (isAlreadyAdded) {
									console.warn("Product already added to the list.");
									// You might want to show a message to the user or take other actions.
								} else {
									console.warn("Product not available.");
									// You might want to show a message to the user or take other actions.
								}
							}

							onProductClicked(product.id);
						}}
						className={`flex flex-row items-center shadow-sm border border-gray-200 bg-white rounded-lg `}
					>
						<img className="h-24 w-32 object-contain" src={product.attachments[0].url} />
						<div className="space-y-1 mx-4">
							<p className="font-semibold">{getLocalizedName(product)}</p>
							<p className="text-md font-medium">
								{product.price} {t("products.label.rupees")}
							</p>
							<p className="text-md font-medium">Available Quantity - {product?.availableQuantity}</p>
							<p className="text-sm font-medium text-blue-500">{t("products.label.addToBasket")}</p>
						</div>
					</div>
				);
			})}
		</div>
	)
}

const ProductDetailsForm = ({
	errors,
	control,
	goBack,
	productIndex,
	products
}: FormProps & {
	productIndex: number
	goBack: () => void
}) => {
	const { fields, append, remove } = useFieldArray({
		name: 'saleItems',
		control,
		keyName: 'customId'
	})
	const saleItem = fields[productIndex]
	const product = products[saleItem?.productId]

	return (
		<div className="space-y-5">
			<div className="flex items-center">
				<Speaker filename="whatQuantityOfProductInTheCart.mp3" />
				<h2 className="text-lg pt-2">{t('products.label.sellQuantity')}</h2>
			</div>
			<div className="flex flex-col justify-center items-center space-y-2">
				<img
					className="w-56 h-44 object-contain rounded"
					src={product?.attachments?.[0]?.url ?? ''}
				/>
				<h2 className="text-lg">{getLocalizedName(product)}</h2>
				<h2 className="text-lg font-light">
					{product?.price} <span>{t('products.label.rupees')}</span>
				</h2>
				<h2 className="ont-light">{product?.description}</h2>
				<h2 className="ont-light">Quantity:{product?.availableQuantity}</h2>
			</div>

			<div>



				<Controller
					control={control}
					name={`saleItems.${productIndex}.quantity`}
					render={({ field: { onChange, value } }) => (
						<div>
							<CounterInput
								onChange={event => {
									onChange(Number(event.target.value) || 1)
								}}
								onNumberChange={number => onChange(number)}
								value={value ?? 1}
								disabled={true}
								minimumValue={1}
								maxValue={product?.maxPerTransaction}
							/>
						</div>
					)}
				/>
				<p className="text-sm text-red-500">
					{errors?.['saleItems']?.[productIndex]?.['quantity']?.message}
				</p>
			</div>
			<div className="flex flex-col justify-center items-center space-y-2 mt-4">
				<button
					type="button"
					onClick={goBack}
					className="p-4 max-w-max flex flex-row justify-between bg-green-500 text-white rounded-full ">
					<CheckIcon className="h-8 w-8" />
				</button>
				<span className="block text-center"> {t('products.buttons.addToCart')}</span>
			</div>
		</div>
	)
}

const CartForm = ({
	errors,
	control,
	register,
	goBack,
	setValue,
	onProductClicked,

	products
}: FormProps & {
	onProductClicked: (productId: ID) => void
	goBack: () => void
}) => {
	const { fields, append, remove } = useFieldArray({
		name: 'saleItems',
		control,
		keyName: 'customId'
	})

	return (
		<div className="space-y-2">
			{fields.length > 0 ? (
				fields.map((saleItem, index) => {
					// const imageCounts = [...Array(saleItem.quantity).keys()].filter(index => index < 4)
					// const productsLeft = (saleItem.quantity ?? 0) - 1

					const product = products[saleItem.productId]

					return (
						<div
							key={index}
							className={`flex flex-row items-center shadow-sm border p-4 border-gray-200 bg-white rounded-lg space-x-4`}>
							<div className="space-y-1 w-full">
								<div className="flex flex-row">
									<div
										className={clsx(
											'flex justify-center border border-gray-200 bg-white rounded-full h-14 w-14 p-2'
										)}>
										<img src={product.attachments?.[0].url} className="h-full object-contain" />
									</div>

									{/* {productsLeft > 0 && (
										<div
											key={'additional'}
											className="border border-gray-200 bg-white -ml-6 aspect-square h-14 rounded-full p-4">
											+{productsLeft}
										</div>
									)} */}
								</div>
								<p className="font-semibold">
									<span className="mx-1">{saleItem.quantity}</span>
									{getLocalizedName(product)}
								</p>
								<p className="font-semibold">
									{(saleItem.quantity ?? 0) * saleItem.amount} {t('products.label.rupees')}
								</p>
							</div>
							<div className="flex flex-col h-full space-y-4">
								<XMarkIcon
									className="text-red-500 w-6 cursor-pointer"
									onClick={event => {
										event.preventDefault()
										remove(index)
									}}
								/>
								<PencilIcon
									className="text-blue-500 w-6 cursor-pointer"
									onClick={event => {
										event.preventDefault()
										onProductClicked(saleItem.productId)
									}}
								/>
							</div>
						</div>
					)
				})
			) : (
				<div className="space-y-2 flex flex-col items-center justify-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<div className="p-6 bg-blue-100 rounded-full">
						<img src={EmptyBagIcon} className="h-1/2 aspect-square" />
					</div>
					<p className="text-lg font-semibold">{t('products.label.cartEmpty')}</p>
					<button
						className="px-8 py-4 flex flex-row justify-between bg-blue-800 text-white rounded-lg text-lg font-semibold"
						onClick={goBack}>
						{t('buttons.goBack')}
					</button>
				</div>
			)}
		</div>
	)
}
